import React, { Fragment, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import Form from '../../../components/Form'
// images
import breadcumb from '../../../images/slider/slider1.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Contact', },
]

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={localStorage.getItem("language") != "cn" ? "Contact Us" : "联系我们"}
                breadcumbMenu={localStorage.getItem("language") != "cn" ? breadcumbMenu : [
                    { name: '首页', route: '/' },
                    { name: '联系我们' }
                ]}
                background={breadcumb}
            />

            <div className="contactusPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="contactUsInfo">
                                <h3>{localStorage.getItem("language") != "cn" ? "Contact us" : "联系我们"}</h3>
                                {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                </p> */}
                                <h4>{localStorage.getItem("language") != "cn" ? "Address" : "地址"}</h4>
                                <span>L5, 33 Franklin St, Adelaide 5000, South Australia</span>
                                <h4>{localStorage.getItem("language") != "cn" ? "Telephone" : "电话"}</h4>
                                <span>08 8227 2388</span>
                                {/* <span>0-123-456-7890</span> */}
                                <h4>{localStorage.getItem("language") != "cn" ? "Email" : "电子邮箱"}</h4>
                                <span>info@yapplawyers.com.au</span>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            {/* <div className="contactUSForm">
                                <h3>Quick Contact Form</h3>
                                <Form
                                    addressInfo={true}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default ContactPage