import React from 'react'
import SectionTitle from '../Title'
import './style.scss'
const pricingpanel = [
    {
        title: 'Lawyer',
        text: 'We are currently looking for a Lawyer with 2 years+ PAE in commercial and/or property area to join our friendly and fast growing team.',
        url: 'career-apply-now',
    },
    {
        title: 'Conveyancer',
        text: 'We are currently looking for an experienced conveyancer to join our friendly and fast growing team.',
        url: 'career-apply-now',
    },
]
const pricingpanelCn = [
    {
        title: '律师',
        text: '我们目前正在寻找一名在商业和/或房地产领域拥有 2 年以上 PAE 的律师加入我们友好且快速发展的团队。',
        url: 'career-apply-now',
    },
    {
        title: '过户师',
        text: '我们目前正在寻找一名经验丰富的过户师加入我们友好且快速发展的团队。',
        url: 'career-apply-now',
    },
]
const pricingpanelT = localStorage.getItem("language") != "cn" ? pricingpanel : pricingpanelCn

const PricingTable = ({ className, title, subTitle }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {pricingpanelT.map((pricing, i) => (
                        <div key={i} className="col-lg-12 col-md-12 col-12">
                            <div className="pricingWrapper">
                                {/* <i className={`fi ${pricing.icon}`}></i> */}
                                <h2>{pricing.title}</h2>
                                {/* <h2>{pricing.price}</h2> */}
                                <p>{pricing.text}</p>
                                <div className="btnStyle">
                                    <a className="readmore" href={pricing.url}> {localStorage.getItem("language") != "cn" ?'Detail': '更多'}</a>

                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default PricingTable