import React, { Fragment, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import JobTable from '../../../components/JobTable'
import FooterArea from '../../../components/FooterArea'
import Form from '../../../components/Form'
// images
import breadcumb from '../../../images/slider/slider1.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Career Detials', },
]
const applyInfo = {
    lawyer:{
        name:"lawyer",
        description:" "
    }
}
const CareerApplyNowPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        // var currentLanguage = localStorage.getItem("language");
        // if (currentLanguage == "cn") {
        //     setAboutText(aboutTextCN);
        //     setAboutTextV(aboutTextVCN);
        //     setAboutTextHua(aboutTextHuaCN);
        //     setAboutTextMoon(aboutTextMoonCN);
        //     setAboutTextJingru(aboutTextJingruCN);
        //     setAboutTextYao(aboutTextYaoCN);
        //     setAboutTextJackson(aboutTextJacksonCN);

        // }
    });
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={localStorage.getItem("language") != "cn" ? "Career Detials" : "工作详情"}
                breadcumbMenu={localStorage.getItem("language") != "cn" ? breadcumbMenu : [
                    { name: '首页', route: '/' },
                    { name: '加入我们' },
                    { name: 'Apply Now' }
                ]}
                background={breadcumb}
            />

            <div className="contactusPageArea">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="contactUsInfo">
                                <h3>{localStorage.getItem("language") != "cn" ? "Lawyer" : "律师"}</h3>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "We are currently looking for a Lawyer with 2 years+ PAE in commercial and/or property area to join our friendly and fast-growing team. " : "我们目前正在寻找一名在商业和/或房地产领域拥有 2 年以上 PAE 的律师加入我们友好且快速发展的团队。"}
                                </p>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "The Requirements" : "要求："}
                                </p>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "2 years+ post admission experience Property/Conveyancing experience preferred Excellent verbal and written communication skills committed and intelligent approach to work A collaborative personality who thrives in a team" : "2 年以上入学后经验物业/过户经验优先 优秀的口头和书面沟通技巧 坚定而聪明的工作方法 具有团队合作精神"}
                                </p>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "To submit your application, please send a cover letter and your curriculum vitae to info@yapplawyers.com.au." : "要提交您的申请，请将求职信和您的简历发送至 info@yapplawyers.com.au。"}
                                </p>

                                
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="contactUsInfo">
                                <h3>{localStorage.getItem("language") != "cn" ? "Conveyancer" : "过户师"}</h3>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "We are looking for a Registered Conveyancer to join our conveyancing team to meet the strong growth we are currently experiencing." : "我们正在寻找注册过户师加入我们的过户团队，以满足我们目前正在经历的强劲增长。"}
                                </p>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "The Requirements" : "要求："}
                                </p>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "2 years+ conveyancing experiences" : "2年以上产权转让经验"}
                                </p>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "Advanced knowledge of Conveyancing Programme, PEXA, Revenue SA, Salis, Microsoft Office Suites & Outlook" : "产权转让计划、PEXA、Revenue SA、Salis、Microsoft Office Suites 和 Outlook 的高级知识"}
                                </p>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "Creating, building and maintaining strong relationships with work colleagues, clients and Business referrers" : "与同事、客户和业务推荐人建立、建立和维持牢固的关系"} 
                                </p>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "High level of organisation and task management skills" : "高水平的组织和任务管理技能"}
                                </p>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "To submit your application, please send a cover letter and your curriculum vitae to info@yapplawyers.com.au." : "要提交您的申请，请将求职信和您的简历发送至 info@yapplawyers.com.au。"}
                                </p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default CareerApplyNowPage