import React from "react";
import SectionTitle from '../Title'
import './style.scss'
import { IoBusinessSharp } from "react-icons/io5";
import { FaUserTie } from "react-icons/fa"
import { AiOutlineTransaction } from "react-icons/ai"
import { GiMoneyStack } from "react-icons/gi"
import { MdFamilyRestroom } from "react-icons/md"
import { BsHouseUpFill } from "react-icons/bs"
import { Link } from 'react-router-dom'

const ServiceArea = ({ className, title, subTitle, services }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {services.map((service, index) => (
                        <div key={index} className="col-lg-4 col-md-6">
                            <div className="serviceWrap">
                                <div className="serviceIcon">
                                    {/* <i className={`fi ${service.icon}`}></i> */}

                                    {service.icon == 'flaticon-employee' && <i className={`fi`}><FaUserTie style={{ fontSize: 40 }} /></i>}
                                    {service.icon == 'flaticon-thief' && <i className={`fi`}><IoBusinessSharp style={{ fontSize: 40 }} /></i>}
                                    {service.icon == 'flaticon-university-graduate-hat' && <i className={`fi`}><AiOutlineTransaction style={{ fontSize: 40 }} /></i>}
                                    {service.icon == 'flaticon-wounded' && <i className={`fi`}><GiMoneyStack style={{ fontSize: 40 }} /></i>}
                                    {service.icon == 'fi-rr-user' && <i className={`fi`}><MdFamilyRestroom style={{ fontSize: 40 }} /></i>}
                                    {service.icon == 'flaticon-house' && <i className={`fi`}><BsHouseUpFill style={{ fontSize: 40 }} /></i>}
                                </div>
                                <div className="serviceContent">
                                    <Link to={service.url} ><h3>{service.title}</h3></Link>

                                    <p>{service.content}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}
export default ServiceArea