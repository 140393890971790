import React, { Fragment, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import JobTable from '../../../components/JobTable'
import FooterArea from '../../../components/FooterArea'
import Form from '../../../components/Form'
// images
import breadcumb from '../../../images/slider/slider1.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Join Us', },
]

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        // var currentLanguage = localStorage.getItem("language");
        // if (currentLanguage == "cn") {
        //     setAboutText(aboutTextCN);
        //     setAboutTextV(aboutTextVCN);
        //     setAboutTextHua(aboutTextHuaCN);
        //     setAboutTextMoon(aboutTextMoonCN);
        //     setAboutTextJingru(aboutTextJingruCN);
        //     setAboutTextYao(aboutTextYaoCN);
        //     setAboutTextJackson(aboutTextJacksonCN);

        // }
    });
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={localStorage.getItem("language") != "cn" ? "Join Us" : "加入我们"}
                breadcumbMenu={localStorage.getItem("language") != "cn" ? breadcumbMenu : [
                    { name: '首页', route: '/' },
                    { name: '加入我们' }
                ]}
                background={breadcumb}
            />

            <div className="contactusPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contactUsInfo">
                                <h3>{localStorage.getItem("language") != "cn" ? "Join us" : "加入我们"}</h3>
                                <p>
                                    {localStorage.getItem("language") != "cn" ? "Are you a talented and motivated lawyer looking for a challenging and rewarding career? YAPP Lawyers is currently seeking experienced lawyers to join our growing team in South Australia." : "您是一位才华横溢、积极进取的律师，正在寻找一份具有挑战性和回报丰厚的职业吗？ YAPP Lawyers 目前正在寻找经验丰富的律师加入我们在南澳大利亚不断壮大的团队。"}
                                </p>
                                <br />
                                <br />
                                <h3>{localStorage.getItem("language") != "cn" ? "Contact us" : "联系我们"}</h3>
                                <h4>{localStorage.getItem("language") != "cn" ? "Address" : "地址"}</h4>
                                <span>L5, 33 Franklin St, Adelaide 5000, South Australia</span>
                                <h4>{localStorage.getItem("language") != "cn" ? "Telephone" : "电话"}</h4>
                                <span>08 8227 2388</span>
                                <h4>{localStorage.getItem("language") != "cn" ? "Email" : "电子邮箱"}</h4>
                                <span>info@yapplawyers.com.au</span>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contactUSForm">
                                <h3>{localStorage.getItem("language") != "cn" ? 'Career Opportunities':'工作机会' }</h3>
                                <JobTable
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default ContactPage