import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/logo/yapp.png'
import './style.scss'
const footerLinks = [
    {
        title: 'Quick Link', menus: [
            { name: 'Home', route: 'index' },
            { name: 'About us', route: 'about' },
            { name: 'Expertise', route: 'expertise' },
            { name: 'Resources', route: 'resources' },
            { name: 'Join Us', route: 'career' },
            { name: 'Contact Us', route: 'contact' },
        ]
    },

    {
        title: 'Practice Area', menus: [
            { name: 'Business Advisory', cnname: '商务咨询', route: 'expertise#ba' },
            { name: 'Commercial Law', cnname: '商业法', route: 'expertise#cl' },
            { name: 'Conveyancing', cnname: '房产过户', route: 'expertise#con' },
            { name: 'Debt Recovery', cnname: '债务追偿', route: 'expertise#dr' },
            { name: 'Family Law', cnname: '家庭法', route: 'expertise#fl' },
            { name: 'Wills and Estate', cnname: '遗嘱遗产', route: 'expertise#we' },
        ]
    },
    {
        title: 'Contact Us', menus: [
            // { name: 'Head Office Address' },
            { name: 'L5, 33 Franklin St, Adelaide 5000', },
            { name: 'South Australia', },
            { name: 'Telephone: 08 8227 2388', },
            { name: 'Email: info@yapplawyers.com.au', },
        ]
    },
]

const footerLinksCn = [
    {
        title: 'Quick Link', menus: [
            { name: '首页', route: 'index' },
            { name: '叶氏律师事务所', route: 'about' },
            { name: '业务领域', route: 'expertise' },
            { name: '新闻资讯', route: 'resources' },
            { name: '加入我们', route: 'career' },
            { name: '联系我们', route: 'contact' },
        ]
    },

    {
        title: 'Practice Area', menus: [
            { name: '商务咨询', cnname: '商务咨询', route: 'expertise#ba' },
            { name: '商业法', cnname: '商业法', route: 'expertise#cl' },
            { name: '房产过户', cnname: '房产过户', route: 'expertise#con' },
            { name: '债务追偿', cnname: '债务追偿', route: 'expertise#dr' },
            { name: '家庭法', cnname: '家庭法', route: 'expertise#fl' },
            { name: '遗嘱遗产', cnname: '遗嘱遗产', route: 'expertise#we' },
        ]
    },
    {
        title: 'Contact Us', menus: [
            // { name: 'Head Office Address' },
            { name: '地址: L5, 33 Franklin St, Adelaide 5000', },
            { name: '南澳大利亚州', },
            { name: '电话: 08 8227 2388', },
            { name: '电子邮箱: info@yapplawyers.com.au', },
        ]
    },
]

const footerLinksT = localStorage.getItem("language") != "cn" ? footerLinks : footerLinksCn;

const FooterArea = () => {
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={logo} alt="YAPP LAWYERS" />
                                </Link>
                                <p>{localStorage.getItem("language") != "cn" ? "We offer high-standard legal advice and competitive pricing to our clients. Our leadership team and staff focus on bridging the gap between regulations and businesses. We are committed to delivering personalised and easy-to-use legal advice in various fields." : "我们为客户提供高标准的法律建议以及有市场竞争力的服务价格。我们的管理团队和员工致力于缩小法律和企业之间的鸿沟。我们致力于在各种领域提供个性化的、便于使用的法律建议。我们以坚持为客户、公众和社区承担经济、社会和管理责任为荣。"}</p>
                            </div>
                        </div>
                        {footerLinksT.map((menu, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <HashLink to={`/${item.route}`}>{item.name}</HashLink> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">

                                <span>
                                    <Link to={`/privacy`} style={{ color: "#DDDDDD" }} >Privacy Policy </Link>-
                                    <Link to={`/terms`} style={{ color: "#DDDDDD" }} > Terms and Conditions </Link>
                                    {localStorage.getItem("language") != "cn" ? "© 2023 YAPP LAWYERS. Powered by" : " © 2023 叶氏律师事务所。 技术支持："}
                                    <a style={{ color: "white" }} target="_blank" href='https://www.cyberate.com.au'> Cyberate</a>.</span>
                            </div>
                            {/* <div className="col-md-4 col-sm-2 col-12">
                                <ul className="socialListFooter">
                                    <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea