import React, { Fragment, useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutUs'
import TeamInfo from '../../../components/TeamInfo'
import ServiceArea from '../../../components/ServiceArea'
import SectionTitle from '../../../components/Title'
import Testmonial from "../../../components/Testmonial";
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/3.jpeg'
import about2 from '../../../images/about/4.jpeg'
// import breadcumb from '../../../images/breadcumb/1.jpg'
import breadcumb from '../../../images/slider/slider1.jpg'
// lawyer img
import portfolio1 from '../../../images/expert/1.jpeg'
import portfolio2 from '../../../images/expert/2.jpeg'
import portfolio3 from '../../../images/expert/3.jpeg'
import portfolio4 from '../../../images/expert/4.jpeg'
import portfolio5 from '../../../images/expert/white1.png'
import portfolio7 from '../../../images/expert/7.jpeg'

import './style.scss'

const aboutTextEN = [
    { text: 'Yapp Lawyers is a boutique law firm located in the Adelaide CBD with a long history. We trace our origins to the 1990s when Mr Yapp commenced practice as a sole practitioner. With over 40 years of expertise offering legal solutions tailored to our clients from multicultural backgrounds, Mr Yapp has a track record of commitment to his clients and ensuring they achieve their business goals.' },
    { text: 'Our lawyers embrace diverse cultures and backgrounds, and provide services in English, Bahasa Malaysia, Bahasa Indonesia, Mandarin, Cantonese, and Hakka. Our law firm offers extensive industry experience and endeavours to foster long term relationships with our clients.' },
    { text: 'Under the leadership of our principal, Mr Yapp, the team strives to provide unequivocal, strategic and holistic legal services in the fields of business advisory, corporate governance, regulatory compliance, commercial law, wills and estate, property, conveyancing, family law, employment law, and debt recovery.' },
]
const aboutTextCN = [
    { text: '叶氏律师事务所是一家悠久历史、坐落在阿德莱德市中心的中型律师事务所。叶氏律师事务所由叶浩鹏律师于上世纪九十年代创立。执业近40年来，叶律师为多元文化背景的客户提供了有针对性的法律解决方案，并因此在本地社区创立了良好的口碑。' },
    { text: '我们的律师拥有不同的文化和背景，精通英语、马来语、印尼语、普通话、广东话以及客家话。叶氏律师事务所重视长久的客户关系，致力于协助客户实现企业的长久兴盛，并协同创造一个有活力的商业环境。' },
    { text: '叶律师带领的团队致力于为各种类型的客户提供清晰的、战略性的、全面的法律服务，涉及领域包括：商务咨询、公司管理、企业合规建议、商业法、遗嘱遗产、不动产法、过户、家庭法、劳工法以及债务追偿等等。' },
]



const aboutTextVEN = [
    { text: 'We offer high-standard legal advice and competitive pricing to our clients. Our leadership team and staff focus on bridging the gap between regulations and businesses. We are committed to delivering personalised and easy-to-use legal advice in various fields. We are proud to insist on shouldering economic, social and governance responsibilities for our clients, our people and our communities.' },
]

const aboutTextVCN = [
    { text: '我们为客户提供高标准的法律建议以及有市场竞争力的服务价格。我们的管理团队和员工致力于缩小法律和企业之间的鸿沟。我们致力于在各种领域提供个性化的、便于使用的法律建议。我们以坚持为客户、公众和社区承担经济、社会和管理责任为荣。' }
]

const aboutTextHuaEN = [
    { text: 'As the Founder and Director of Yapp Lawyers Pty Ltd, Hau Pehn was admitted as a legal practitioner in South Australia in 1979. He has practised law in English, Mandarin, Bahasa Malaysia, Bahasa Indonesia, and the Chinese dialects of Cantonese and Hakka, including 10 years in Malaysia. He was awarded an LLM (Commercial) in 1995.' },
    { text: 'Hau Pehn has extensive experience in commercial law, including liquor licensing, commercial leases, property law, and wills and estates.' },
    { text: 'Hau Pehn served as a Member of the Equal Opportunity Tribunal of South Australia from 2009 to 2015.' },
    { text: 'Hau Pehn has provided pro bono legal assistance to community organisations in Adelaide for 30 years.' },
]


const aboutTextHuaCN = [
    { text: '作为叶氏律师事务所的创始人和董事，叶浩鹏律师1979年在南澳成为执业律师。此后，他在马来西亚执业10年。他于1995年获得商业法硕士学位。执业期间，叶律师熟练使用英语、普通话、马来语、印尼语、粤语和客家话为客户提供法律服务。' },
    { text: '叶律师在商业法方面拥有资深的执业经验，所擅长的领域包括商业合约、不动产法、酒牌申请、商业租约、遗嘱遗产等等。' },
    { text: '从2009到2015年期间，叶律师曾任南澳州公平机会仲裁庭的仲裁员。' },
    { text: '叶律师在阿德莱德已经为多个社区组织提供了近30年的无偿法律援助。' },

]

const aboutTextMoonEN = [
    { text: 'Moon graduated from Northumbria University in the UK and Griffith University in Australia. After obtaining a master’s degree, she began work in the business consultation area in London, Shanghai and Adelaide.' },
    { text: 'She then obtained a Registered Migration Agent License (MARN 1682653) and worked as a Business and Investment Migration Officer in Skilled & Business Migration, South Australia Government, providing policy guidance for business migrants, and creating investment opportunities for local businesses.' },
    { text: 'As the Practice Manager Moon is responsible for managing and overseeing the day-to-day operations of the firm, developing and implementing the firm’s corporate strategy and business plan.' },
]

const aboutTextMoonCN = [
    { text: '许经理毕业于英国诺森比亚大学和澳大利亚格林菲斯大学。获得硕士学位后，曾先后在伦敦、上海和阿德莱德的商业咨询领域就职。' },
    { text: '她随后取得注册移民师牌照(MARN 1682653)并就职于南澳州移民局，担任商业和投资移民官，为商业移民提供政策指导，并为本地企业创造投资机会。' },
    { text: '许经理主要负责管理和监督律所的日常运营、设计和实施律所的战略规划以及商业计划。' },

]


const aboutTextJingruEN = [
    { text: 'Jing graduated from Shandong University with her first Bachelor of Laws degree in China. After she moved to Australia, she studied at the University of Adelaide and acquired her second Bachelor of Laws degree in Australia. In 2015, she was admitted to practice in South Australia.' },
    { text: 'She is a qualified commercial lawyer specialising in business law, conveyancing, wills and estate and immigration law. She is endeavouring to provide clients with sophisticated and practical legal and business solutions.' },

]

const aboutTextJingruCN = [
    { text: '崔律师毕业于山东大学并获得了中国的法学学士学位。来到澳大利亚后，她在阿德莱德大学求学并获得澳大利亚的法学学士学位。' },
    { text: '崔律师精通中澳两国法律，擅长商业法和移民法，在为投资移民以及其他新移民客户提供商业法、过户、遗嘱遗产等方面的法律服务拥有资深的经验，致力于为客户提供精准实用的法律和商业解决方案。' },

]

const aboutTextYaoEN = [
    { text: 'Yao was admitted as a legal practitioner in South Australia and commenced to practise at our law firm in 2022.' },
    { text: 'She has practised in a wide range of fields, including commercial law, property law, debt recovery, wills and estates and family law.' },
    { text: 'She is enthusiastic about assisting clients to solve their issues and is committed to achieving the best outcome.' },
]

const aboutTextYaoCN = [
    { text: '黄律师曾是新南威尔士州执业律师，南澳州执业律师，并在2022年就职于叶氏律师事务所。' },
    { text: '她在多个法律领域都有丰富的案件操作经验，其中包括：商业法、不动产法、债务追偿、遗嘱遗产以及家庭法。' },
    { text: '她善于与客户进行深度沟通交流，协助客户解决法律问题，并致力于为客户追求最佳结果。' },
]

const aboutTextMarkEN = [
    { text: 'Mark Gustavsson is a highly experienced litigation and transaction lawyer. He has a proven track record of success in handling complex legal matters, including commercial litigation, intellectual property, asset recoveries, consumer protection, employment law, insurance law, and more. He has extensive experience in negotiating and drafting commercial agreements, advising on regulatory compliance issues, and handling complex transactions, including mergers & acquisitions.' },
    { text: 'In addition to his trial and interlocutory work, Mark is also an accomplished appellate advocate, having co-ordinated and argued successfully in the New South Wales Court of Appeal against a national fast-food franchisor. He is well-known for his persuasive and articulate advocacy skills, and his ability to present complex legal arguments in a clear and concise manner. ' },


]

const aboutTextMarkCN = [
    { text: 'Mark Gustavsson 是一位经验丰富的诉讼和交易律师。 他在处理复杂法律事务方面拥有优异的成功记录，包括商业诉讼、知识产权、资产追回、消费者保护、就业法、保险法等。 他在谈判和起草商业协议、就监管合规问题提供咨询以及处理复杂交易（包括并购）方面拥有丰富的经验。。' },
    { text: '马克还是一位出色的上诉律师，曾在新南威尔士州上诉法院针对全国性快餐特许经营商进行协调和成功辩护，并因此在业界享有盛誉。 他以其具有说服力和清晰的辩护技巧以及简洁有力地提出复杂法律论点的能力而闻名。' },
]



const servicesEn = [
    {
        icon: 'flaticon-employee',
        title: 'Business Advisory',
        route: 'expertise#ba',
        content: 'Yapp Lawyers works consistently and closely with business clients who operate their companies in South Australia and other States.'
    },
    {
        icon: 'flaticon-thief',
        title: 'Commercial Law',
        content: 'Our commercial team aims to combine legal knowledge with commercial insights to facilitate your businesses and further your interests. '
    },
    {
        icon: 'flaticon-house',
        title: 'Conveyancing',
        content: 'Our law firm provides high-quality conveyancing services to clients, including vendors and purchasers from various individual property transactions to commercial development projects.'
    },
    {
        icon: 'flaticon-wounded',
        title: 'Debt Recovery',
        content: 'Our debt recovery professionals have a dedication to helping clients by providing feasible and tailored strategies.'
    },
    {
        icon: 'fi-rr-user',
        title: 'Family Law',
        content: 'Our family law team aims to offer straightforward legal advice to ease stress.'
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Wills and Estate',
        content: 'It is never too early to formulate a succession plan to protect your assets and the benefits of your loved ones. '
    },
]

const servicesCn = [
    {
        icon: 'flaticon-employee',
        title: '商务咨询',
        content: '我们专业的律师团队成功地为商业客户提供量身打造的商业计划，以符合澳洲相关政府机构的各种强制性要求，其中包括联邦移民局。'
    },
    {
        icon: 'flaticon-thief',
        title: '商业法',
        content: '我们的商业团队致力于融合商业洞察力和法律知识以促成您的业务以及促进您的企业收益增长。'
    },
    {
        icon: 'flaticon-house',
        title: '房产过户',
        content: '我们律所为客户提供高质量的过户服务，我们的客户包括住宅买卖交易中的买方和卖方，以及商业地产开发项目中的买方和卖方。'
    },
    {
        icon: 'flaticon-wounded',
        title: '债务追偿',
        content: '我们的律师致力于帮助客户并为其提供可行的、量身打造的法律策略。'
    },
    {
        icon: 'fi-rr-user',
        title: '家庭法',
        content: '我们的家庭法团队致力于提供直接有效的法律建议以减轻您的压力。'
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: '遗嘱遗产',
        content: '一份精心准备的遗产规划意味着您意属的遗产继承人将可以避免令人痛苦的遗产纠纷。'
    },
]


const AboutPage = () => {
    const [servicesI, setServices] = useState(servicesEn);
    const [aboutTextI, setAboutText] = useState(aboutTextEN);
    const [aboutTextV, setAboutTextV] = useState(aboutTextVEN);



    const [aboutTextHua, setAboutTextHua] = useState(aboutTextHuaEN);
    const [aboutTextMoon, setAboutTextMoon] = useState(aboutTextMoonEN);
    const [aboutTextJingru, setAboutTextJingru] = useState(aboutTextJingruEN);
    const [aboutTextYao, setAboutTextYao] = useState(aboutTextYaoEN);
    const [aboutTextMark, setAboutTextMark] = useState(aboutTextMarkEN);



    const [breadcumbMenu, setBreadcumb] = useState([
        { name: 'Home', route: '/' },
        { name: 'About us' }
    ])
    const [language, setLanguage] = useState("en");
    useEffect(() => {
        // window.scrollTo(0, 0);
        var currentLanguage = localStorage.getItem("language");
        if (currentLanguage == "cn") {
            setAboutText(aboutTextCN);
            setAboutTextV(aboutTextVCN);
            setAboutTextHua(aboutTextHuaCN);
            setAboutTextMoon(aboutTextMoonCN);
            setAboutTextJingru(aboutTextJingruCN);
            setAboutTextYao(aboutTextYaoCN);
            setAboutTextMark(aboutTextMarkCN);

        }
    });
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={localStorage.getItem("language") != "cn" ? "About Us" : "关于我们"}
                breadcumbMenu={localStorage.getItem("language") != "cn" ? breadcumbMenu : [
                    { name: '首页', route: '/' },
                    { name: '关于我们' }
                ]}
                background={breadcumb}
            />
            {/* <Service className="serviceArea mt-0" /> */}
            <About
                className="aboutArea aboutAreaStyleTwo"
                title={localStorage.getItem("language") != "cn" ? "Introduction" : "简介"}
                subTitle={localStorage.getItem("language") != "cn" ? "We Are Experts" : ""}
                images={about}
                orderLast="order-last"
                pragraphs={aboutTextI}
            />
            <ServiceArea
                className="ourServiceArea"
                title={localStorage.getItem("language") != "cn" ? "How Can We Help You" : "业务领域"}
                subTitle={localStorage.getItem("language") != "cn" ? "Area Of Practice" : ""}
                services={localStorage.getItem("language") != "cn" ? servicesEn : servicesCn}
            />

            <About
                className="aboutArea aboutAreaStyleTwo"
                title={localStorage.getItem("language") != "cn" ? "Our Vision" : "我们的视野"}
                subTitle=" "
                images={about2}
                orderLast="order-last"
                pragraphs={aboutTextV}
                id="vision"
            />

            <TeamMember
                title={localStorage.getItem("language") != "cn" ? "Our Team" : "我们的团队"}
                subTitle=""
                className="teamArea teamAreaStyleTwo"
                noGutters={true}
                id="team"
            />


            <TeamInfo
                className="aboutArea aboutAreaStyleTwo"
                title={localStorage.getItem("language") != "cn" ? "Hau Pehn Yapp" : "叶浩鹏"}
                subTitle={localStorage.getItem("language") != "cn" ? "Founder/Principal Lawyer" : "创始人 / 首席律师"}
                images={portfolio1}
                orderLast="order-last"
                pragraphs={aboutTextHua}
                id="lawyer1"
            />

            <TeamInfo
                className="aboutArea aboutAreaStyleTwo"
                title={localStorage.getItem("language") != "cn" ? "Moon Xu" : "许晖"}
                subTitle={localStorage.getItem("language") != "cn" ? "Practice Manager" : "运营经理"}
                images={portfolio2}
                orderLast="order-last"
                pragraphs={aboutTextMoon}
                id="lawyer2"
            />
            <TeamInfo
                className="aboutArea aboutAreaStyleTwo"
                title={localStorage.getItem("language") != "cn" ? "Mark Gustavsson" : "Mark Gustavsson"}
                subTitle={localStorage.getItem("language") != "cn" ? "Special Counsel / Litigation Lawyer" : "法律顾问 / 诉讼律师"}
                images={portfolio7}
                orderLast="order-last"
                pragraphs={aboutTextMark}
                id="lawyer5"
            />



            <TeamInfo
                className="aboutArea aboutAreaStyleTwo"
                title={localStorage.getItem("language") != "cn" ? "Jingru Cui" : "崔静茹"}
                subTitle={localStorage.getItem("language") != "cn" ? "Lawyer" : "律师"}
                images={portfolio3}
                orderLast="order-last"
                pragraphs={aboutTextJingru}
                id="lawyer3"
            />




            <TeamInfo
                className="aboutArea aboutAreaStyleTwo"
                title={localStorage.getItem("language") != "cn" ? "Yao Huang" : "黄瑶"}
                subTitle={localStorage.getItem("language") != "cn" ? "Lawyer" : "律师"}
                images={portfolio4}
                orderLast="order-last"
                pragraphs={aboutTextYao}
                id="lawyer4"
            />




            {/* <TeamInfo
                className="aboutArea aboutAreaStyleTwo"

                title={localStorage.getItem("language") != "cn" ? "Jackson Ren" : "任讯辉"}
                subTitle={localStorage.getItem("language") != "cn" ? "Registered Conveyancer" : "过户师"}
                images={portfolio5}
                orderLast="order-last"
                pragraphs={aboutTextJackson}
                id="lawyer5"
            /> */}


            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            /> */}
            {/* <Testmonial
                className="testmonialArea pt100"
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default AboutPage