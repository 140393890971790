import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import { Link } from 'react-router-dom'
import 'react-modal-video/scss/modal-video.scss'
import './style.scss'

const TeamInfo = ({ subTitle, title, videoId, className, signature, pragraphs, images, orderLast, id }) => {
    const [video, setVideo] = useState(false)
    return (
        <div className={className} id={id}>
            <div className="container">
                <div className="row">
                    <div className='col-lg-4 col-md-4'>
                        <div className="testimoialImages">
                            <img src={images} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 offset-lg-1 col-md-7">
                        <div className="aboutContent">
                            {subTitle && <span>{subTitle}</span>}
                            <h2>{title}</h2>
                            {pragraphs.map((pragraph, i) => (
                                <p key={i}>{pragraph.text}</p>
                            ))}
                            <div className="btnStyle">

                            </div>
                            {/* {signature && <div className="signature">
                                <img src={signature} alt="" />
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TeamInfo