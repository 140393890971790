import React, { Fragment, useState, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import HeroSlider from '../../../components/HeroSlider'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Portfolio from '../../../components/Portfolio'
import Testmonial from "../../../components/Testmonial";
import ContactArea from '../../../components/ContactArea'
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/3.jpeg'
import signature from '../../../images/about/1.png'

// images
import portfolio1 from '../../../images/studies/1.jpg'
import portfolio2 from '../../../images/studies/2.jpg'
import portfolio3 from '../../../images/studies/3.jpg'
import portfolio4 from '../../../images/studies/4.jpg'
import portfolio5 from '../../../images/studies/5.jpg'

const aboutTextEn = [
    { text: 'Yapp Lawyers is a boutique law firm located in the Adelaide CBD with a long history. We trace our origins to the 1990s when Mr Yapp commenced practice as a sole practitioner. With over 40 years of expertise offering legal solutions tailored to our clients from multicultural backgrounds, Mr Yapp has a track record of commitment to his clients and ensuring they achieve their business goals.' },
    { text: 'Our lawyers embrace diverse cultures and backgrounds, and provide services in English, Bahasa Malaysia, Bahasa Indonesia, Mandarin, Cantonese, and Hakka. Our law firm offers extensive industry experience and endeavours to foster long term relationships with our clients.' },
    { text: 'Under the leadership of our principal, Mr Yapp, the team strives to provide unequivocal, strategic and holistic legal services in the fields of business advisory, corporate governance, regulatory compliance, commercial law, wills and estate, property, conveyancing, family law, employment law, and debt recovery.'}
]

const aboutTextCN = [
    { text: '叶氏律师事务所是一家悠久历史、坐落在阿德莱德市中心的中型律师事务所。叶氏律师事务所由叶浩鹏律师于上世纪九十年代创立。执业近40年来，叶律师为多元文化背景的客户提供了有针对性的法律解决方案，并因此在本地社区创立了良好的口碑。' },
    { text: '我们的律师拥有不同的文化和背景，精通英语、马来语、印尼语、普通话、广东话以及客家话。叶氏律师事务所重视长久的客户关系，致力于协助客户实现企业的长久兴盛，并协同创造一个有活力的商业环境。' },
    { text: '叶律师带领的团队致力于为各种类型的客户提供清晰的、战略性的、全面的法律服务，涉及领域包括：商务咨询、公司管理、企业合规建议、商业法、遗嘱遗产、不动产法、过户、家庭法、劳工法以及债务追偿等等。' },
]

const heroSliders = [
    {
        images: 'slideWrapperOne',
        title: 'Legal solutions for ',
        subTitle: 'business and individual needs.',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperTwo',
        title: 'Focused on relationships, ',
        subTitle: 'committed to dynamic legal insights',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperThree',
        title: 'Excellence, experience, integrity',
        subTitle: '',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperFour',
        title: 'For every challenge, we provide a solution ',
        subTitle: '',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
]

const heroSlidersCn = [
    // {
    //     images: 'slideWrapperOne',
    //     title: '我们为您的公正而战',
    //     subTitle: '就像朋友一样。',
    //     text: '最有才华的律师事务所',
    //     button: 'Contact us now'
    // },
    // {
    //     images: 'slideWrapperTwo',
    //     title: '我们为您的公正而战',
    //     subTitle: '就像朋友一样。',
    //     text: '最有才华的律师事务所',
    //     button: 'Contact us now'
    // },
    {
        images: 'slideWrapperOne',
        title: 'Legal solutions for ',
        subTitle: 'business and individual needs.',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperTwo',
        title: 'Focused on relationships, ',
        subTitle: 'committed to dynamic legal insights',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperThree',
        title: 'Excellence, experience, integrity',
        subTitle: '',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperFour',
        title: 'For every challenge, we provide a solution ',
        subTitle: '',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
]

const servicesEn = [
    {
        icon: 'flaticon-employee',
        title: 'Business Advisory',
        content: 'Yapp Lawyers works consistently and closely with business clients who operate their companies in South Australia and other States.',
        url: '/expertise#ba'
    },
    {
        icon: 'flaticon-thief',
        title: 'Commercial Law',
        content: 'Our commercial team aims to combine legal knowledge with commercial insights to facilitate your businesses and further your interests. ',
        url: '/expertise#cl'
    },
    {
        icon: 'flaticon-house',
        title: 'Conveyancing',
        content: 'Our law firm provides high-quality conveyancing services to clients, including vendors and purchasers from various individual property transactions to commercial development projects.',
        url: '/expertise#con'
    },
    {
        icon: 'flaticon-wounded',
        title: 'Debt Recovery',
        content: 'Our debt recovery professionals have a dedication to helping clients by providing feasible and tailored strategies.',
        url: '/expertise#dr'
    },
    {
        icon: 'fi-rr-user',
        title: 'Family Law',
        content: 'Our family law team aims to offer straightforward legal advice to ease stress.',
        url: '/expertise#fl'
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Wills and Estate',
        content: 'It is never too early to formulate a succession plan to protect your assets and the benefits of your loved ones. ',
        url: '/expertise#we'
    },
]

const servicesCn = [
    {
        icon: 'flaticon-employee',
        title: '商务咨询',
        content: '我们专业的律师团队成功地为商业客户提供量身打造的商业计划，以符合澳洲相关政府机构的各种强制性要求，其中包括联邦移民局。',
        url: '/expertise#ba'
    },
    {
        icon: 'flaticon-thief',
        title: '商业法',
        content: '我们的商业团队致力于融合商业洞察力和法律知识以促成您的业务以及促进您的企业收益增长。',
        url: '/expertise#cl'
    },
    {
        icon: 'flaticon-house',
        title: '房产过户',
        content: '我们律所为客户提供高质量的过户服务，我们的客户包括住宅买卖交易中的买方和卖方，以及商业地产开发项目中的买方和卖方。',
        url: '/expertise#con'
    },
    {
        icon: 'flaticon-wounded',
        title: '债务追偿',
        content: '我们的律师致力于帮助客户并为其提供可行的、量身打造的法律策略。',
        url: '/expertise#dr'
    },
    {
        icon: 'fi-rr-user',
        title: '家庭法',
        content: '我们的家庭法团队致力于提供直接有效的法律建议以减轻您的压力。',
        url: '/expertise#fl'
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: '遗嘱遗产',
        content: '一份精心准备的遗产规划意味着您意属的遗产继承人将可以避免令人痛苦的遗产纠纷。',
        url: '/expertise#we'
    },
]


const HomePageOne = () => {
    const [aboutText, setAboutText] = useState(aboutTextEn);
    const [servicesI, setServices] = useState(servicesEn);
    const [language, setLanguage] = useState("en");
    useEffect(() => {
        window.scrollTo(0, 0);
        var currentLanguage = localStorage.getItem("language");
        if (currentLanguage == "cn") {
            setLanguage("cn");
            setServices(servicesCn);
            setAboutText(aboutTextCN);
        }
    });
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea" />
            </header>
            <HeroSlider
                sliders={language == "en" ? heroSliders : heroSlidersCn}
                className="heroSliderArea" />
            {/* <Service className="serviceArea" /> */}
            <About
                className="aboutArea"
                title={language == "en" ? "About Us" : "关于我们"}
                images={about}
                signature={signature}
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea"
                title={language == "en" ? "How Can We Help You" : "业务领域"}
                subTitle={language == "en" ? "Area Of Practice" : ""}
                services={servicesI}
            />
            {/* <Portfolio
                className="portfolioArea"
                title="Our Resent Case Studies"
                subTitle="Here Our Best Work"
                portfolioItem={portfolioItem}
            /> */}
            {/* <Testmonial
                className="testmonialArea"
            /> */}
            {/* <ContactArea
                className="contactArea"
            /> */}
            <TeamMember
                title={language == "en" ? "Our Team" : "我们的团队"}
                subTitle={language == "en" ? "Meet Our Experts" : ""}
                className="teamArea"
                slider={true}
            />
            {/* <CounterArea
                className="counterArea"
            /> */}
            {/* <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            /> */}
            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default HomePageOne