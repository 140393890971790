import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import blog1 from '../../images/blog-page/accc.png'
import blog2 from '../../images/blog-page/apra.png'
import blog3 from '../../images/blog-page/ato.png'
import blog4 from '../../images/blog-page/asic.png'
import blog5 from '../../images/blog-page/businessSA.svg'
import blog6 from '../../images/blog-page/community.jpeg'
import blog7 from '../../images/blog-page/caa.png'
import blog8 from '../../images/blog-page/agdha.png'
import blog9 from '../../images/blog-page/fwo.png'
import blog10 from '../../images/blog-page/firb.png'
import blog11 from '../../images/blog-page/hcoa.jpeg'
import blog12 from '../../images/blog-page/ls.jpeg'
import blog13 from '../../images/blog-page/oaic.png'
import blog14 from '../../images/blog-page/ppsr.png'
import blog15 from '../../images/blog-page/rvsa.jpeg'
import blog16 from '../../images/blog-page/gvsa.png'
import avatar from '../../images/blog-page/6.jpg'



const blogPost = [
    {
        url: "https://www.accc.gov.au/",
        title: 'Australian Competition and Consumer Commission',
        text: 'The ACCC promotes competition and fair trade in markets to benefit consumers, businesses, and the community. We also regulate national infrastructure services. Our primary responsibility is to ensure that individuals and businesses comply with Australian competition, fair trading, and consumer protection laws - in particular the Competition and Consumer Act 2010.',
        logo: blog1,
    },
    {
        url: "https://www.apra.gov.au/",
        title: 'Australian Prudential Regulation Authority',
        text: 'The Australian Prudential Regulation Authority (APRA) is Australia’s financial system prudential regulator. APRA is responsible for the licensing and regulatory oversight of financial entities to protect the interests of depositors, insurance policyholders and superannuation fund members. It is also required to balance the objectives of financial safety and efficiency, competition, contestability and competitive neutrality and, in balancing these objectives, is to promote financial system stability.',
        logo: blog2,
    },
    {
        url: "https://asic.gov.au/",
        title: 'Australian Securities and Investments Commission',
        text: 'The Australian Securities and Investments Commission (ASIC) is Australia’s financial markets conduct regulator. ASIC is responsible for promoting a fair, transparent and efficient financial system for all. ASIC regulates the conduct of Australian companies, financial markets, financial services organisations (including banks, life and general insurers and superannuation funds) and professionals who deal in and advise on investments, superannuation, insurance, deposit-taking and credit. ASIC is also responsible for authorisations to operate in industries it regulates.',
        logo: blog4,
    },
    {
        url: "https://www.ato.gov.au/",
        title: 'Australian Taxation Office',
        text: 'The Australian Taxation Office (ATO) is the principal revenue collection agency of the Australian Government. Our role is to effectively manage and shape the tax and superannuation systems that support and fund services for Australians.',
        logo: blog3,
    },
    {
        url: "https://business-sa.com/",
        title: 'Business SA',
        text: 'Founded in 1839, we are South Australia’s Chamber of Commerce and Industry and the state’s largest membership-based employer organisation. We are not a government organisation — we are independent of government. We represent business owners and operators across 19 different industry sectors. Our sole purpose is to create a stronger South Australia by delivering the best possible environment for businesses — like yours — to succeed.',
        logo: blog5,
    },
    {
        url: "https://www.communityjusticesa.org.au/",
        title: 'Community Justice Services SA',
        text: 'We provide free legal advice in the areas of civil, criminal and family law to most members of the community and free court representation in both the State and Federal Courts as well as case work, all subject to eligibility.',
        logo: blog6,
    },
    {
        url: "https://www.courts.sa.gov.au/",
        title: 'Courts Administration Authority of South Australia',
        text: 'The Courts Administration Authority (CAA) administers justice on behalf of the people of South Australia. It is independent of the legislative and executive arms of government and is the means by which the judiciary of the state controls the administration of courts through which judicial power is exercised.',
        logo: blog7,
    },
    {
        url: "https://www.homeaffairs.gov.au/",
        title: 'Department of Home Affairs',
        text: 'Home Affairs brings together Australia\'s federal law enforcement, national and transport security, criminal justice, emergency management, multicultural affairs, settlement services and immigration and border-related functions, working together to keep Australia safe.',
        logo: blog8,
    },
    {
        url: "https://www.fairwork.gov.au/",
        title: 'Fair Work Ombudsman',
        text: 'The Fair Work Ombudsman (FWO), is an independent statutory agency of the Government of Australia that serves as the central point of contact for free advice and information on the Australian national workplace relations system. The Office of the Fair Work Ombudsman also investigates workplace complaints and enforces compliance with national workplace laws.',
        logo: blog9,
    },
    {
        url: "https://firb.gov.au/",
        title: 'Foreign Investment Review Board',
        text: 'The Foreign Investment Review Board (the Board) is a non statutory body established in 1976 to advise the Treasurer and the Government on Australia\'s Foreign Investment Policy (the Policy) and its administration. The Board\'s functions are advisory only. Responsibility for making decisions about the Policy and proposals rests with the Treasurer. The Treasury\'s Foreign Investment Division (the Division) administers Australia’s foreign investment regulatory framework and supports the Board’s work.',
        logo: blog10,
    },
    {
        url: "https://www.hcourt.gov.au/",
        title: 'High Court of Australia',
        text: 'The High Court is the highest court in the Australian judicial system. It was established in 1901 by Section 71 of the Constitution. The functions of the High Court are to interpret and apply the law of Australia; to decide cases of special federal significance including challenges to the constitutional validity of laws and to hear appeals, by special leave, from Federal, State and Territory courts.',
        logo: blog11,
    },
    {
        url: "https://www.landservices.com.au/",
        title: 'Land Services SA',
        text: 'Land Services SA was appointed as the exclusive Service Provider to the South Australian Government for a range of transactional land services and property valuation services previously delivered through the Lands Titles Office and State Valuation Office in October 2017.',
        logo: blog12,
    },
    {
        url: "https://www.oaic.gov.au/",
        title: 'Office of the Australian Information Commissioner',
        text: 'We are an independent agency within the Attorney-General’s portfolio. Our primary functions are privacy, freedom of information and government information policy. Our responsibilities include conducting investigations, reviewing decisions, handling complaints, and providing guidance and advice.',
        logo: blog13,
    },
    {
        url: "https://www.ppsr.gov.au/",
        title: 'Personal Property Securities Register',
        text: 'The Personal Property Securities Register, most commonly known as the PPSR, is an official government register. It is a public noticeboard of security interests in personal property that is managed by the Registrar of Personal Property Securities. People can search the PPSR to see if someone has registered a security interest over personal property (which they may want to do before buying property or lending money to someone).',
        logo: blog14,
    },
    {
        url: "https://www.revenuesa.sa.gov.au/",
        title: 'Revenue SA',
        text: 'RevenueSA, through the Commissioner of State Taxation, is responsible for: management, collection and enforcement of South Australia\'s taxation revenue; management of various grant schemes; management and collection of the fixed property component of the Emergency Services Levy (ESL); and management of government rebate incentive schemes. The revenue collected (apart from ESL which specifically funds the provision of emergency services), is used by the Government to fund the provision of essential services including public health and safety, education and law and order for South Australian citizens.',
        logo: blog15,
    },
    {
        url: "https://www.sa.gov.au/",
        title: 'SA Government',
        text: 'SAcommunity is South Australia\'s community information resource to find help from government, non-government and community services throughout SA in the areas of health, welfare, housing, education, community participation, information, legal services, arts and recreation.',
        logo: blog16,
    },
    
]

const blogPostCn = [
    
        {
            url: "https://www.accc.gov.au/",
            title: '澳洲公平竞争和消费者委员会',
            text: '澳洲公平竞争和消费者委员会促进市场竞争和公平贸易，使消费者、企业和社区受益。我们还监管国家基础设施服务。我们的主要职责是确保个人和企业遵守澳大利亚竞争、公平交易和消费者保护法，尤其是2010 年竞争和消费者法。',
            logo: blog1,
        },
        {
            url: "https://www.apra.gov.au/",
            title: '澳大利亚审慎监管局',
            text: '澳大利亚审慎监管局（APRA）是澳大利亚金融体系的审慎监管机构。APRA 负责金融实体的许可和监管监督，以保护存款人、保单持有人和养老基金成员的利益。它还需要平衡金融安全和效率、竞争、可竞争性和竞争中性的目标，并在平衡这些目标的过程中促进金融体系的稳定。',
            logo: blog2,
        },
        {
            url: "https://asic.gov.au/",
            title: '澳大利亚证券与投资委员会',
            text: '澳大利亚证券与投资委员会（ASIC） 监管澳大利亚公司、金融市场、金融服务组织（包括银行、人寿和普通保险公司以及养老基金）以及从事投资、养老金、保险、存款和信贷交易并提供建议的专业人士的行为。ASIC 还负责授权在其监管的行业中运营。',
            logo: blog4,
        },
        {
            url: "https://www.ato.gov.au/",
            title: '澳大利亚税务局',
            text: '澳大利亚税务局 (ATO) 是澳大利亚政府的主要税收征管机构,我们的职责是有效管理和塑造支持和资助澳大利亚人服务的税收和退休金制度。',
            logo: blog3,
        },
        {
            url: "https://business-sa.com/",
            title: '南澳商务局',
            text: '我们成立于 1839 年，是南澳大利亚州工商会和该州最大的会员制雇主组织。我们不是政府组织，我们独立于政府。我们代表企业。目的是通过为像您这样的企业提供最佳环境来取得成功，从而打造更强大的南澳大利亚。',
            logo: blog5,
        },
        {
            url: "https://www.communityjusticesa.org.au/",
            title: '南澳社区公平服务部',
            text: '我们为社区的大多数成员提供民法、刑法和家庭法领域的免费法律咨询，并在州和联邦法院提供免费法庭代表以及案例工作，所有这些都取决于资格。',
            logo: blog6,
        },
        {
            url: "https://www.courts.sa.gov.au/",
            title: '南澳法院',
            text: '南澳法院代表南澳大利亚人民执行司法。它独立于政府的立法和行政部门，是国家司法部门控制法院行政管理的手段，法院通过该行政机构行使司法权。',
            logo: blog7,
        },
        {
            url: "https://www.homeaffairs.gov.au/",
            title: '澳大利亚内务部',
            text: '澳大利亚内务部汇集了澳大利亚联邦执法、国家和运输安全、刑事司法、应急管理、多元文化事务、定居服务以及移民和边境相关职能，共同确保澳大利亚的安全。',
            logo: blog8,
        },
        {
            url: "https://www.fairwork.gov.au/",
            title: '公平工作调查专员署',
            text: '公平工作调查专员署是澳大利亚政府的独立法定机构，是澳大利亚国家工作场所关系系统免费咨询和信息的中心联络点. 公平工作监察员办公室还调查工作场所投诉并强制遵守国家工作场所法律。',
            logo: blog9,
        },
        {
            url: "https://firb.gov.au/",
            title: '外商投资审核委员会',
            text: '外商投资审查委员会（委员会）是一个非法定机构，成立于 1976 年，负责就澳大利亚的外商投资政策（政策）及其管理向财政部长和政府提供建议。董事会的职能仅为咨询。就政策和提案做出决定的责任在于财务主管。财政部的外商投资部（该部）负责管理澳大利亚的外国投资监管框架并支持委员会的工作。',
            logo: blog10,
        },
        {
            url: "https://www.hcourt.gov.au/",
            title: '澳大利亚最高法院',
            text: '高等法院是澳大利亚司法系统中的最高法院。它于 1901 年根据宪法第 71 条成立。高等法院的职能是解释和适用澳大利亚法律；决定具有特殊联邦意义的案件，包括对法律的宪法有效性提出质疑，并根据特别许可审理来自联邦、州和领地法院的上诉。',
            logo: blog11,
        },
        {
            url: "https://www.landservices.com.au/",
            title: '南澳土地局',
            text: '南澳土地局被任命为南澳大利亚政府的独家服务提供商，提供一系列交易性土地服务和财产估价服务，此前这些服务于 2017 年 10 月通过土地所有权办公室和州估价办公室提供。',
            logo: blog12,
        },
        {
            url: "https://www.oaic.gov.au/",
            title: '澳大利亚信息专员办公室',
            text: '我们是总检察长管辖范围内的一个独立机构。我们的主要职能是隐私、信息自由和政府信息政策。我们的职责包括开展调查、审查决定、处理投诉以及提供指导和建议。',
            logo: blog13,
        },
        {
            url: "https://www.ppsr.gov.au/",
            title: '个人资产担保登记处',
            text: '个人资产担保登记处，通常称为 PPSR，是官方政府登记册。它是由个人财产证券登记处管理的个人财产担保权益的公告牌。人们可以搜索 PPSR 以查看是否有人注册了个人财产的担保权益（他们可能希望在购买财产或向某人借钱之前这样做）。',
            logo: blog14,
        },
        {
            url: "https://www.revenuesa.sa.gov.au/",
            title: '南澳税务局',
            text: '南澳税务局通过州税务专员负责：南澳大利亚州税收的管理、征收和执行；管理各种赠款计划；管理和征收紧急服务税 (ESL) 的固定财产部分；和政府回扣奖励计划的管理。所收取的收入（除专门为提供紧急服务提供资金的 ESL 外）由政府用于资助提供基本服务，包括为南澳大利亚公民提供的公共健康和安全、教育以及法律和秩序。',
            logo: blog15,
        },
        {
            url: "https://www.sa.gov.au/",
            title: '南澳州政府',
            text: '南澳州政府是南澳大利亚的社区信息资源，用于：在健康、福利、住房、教育、社区参与、信息、法律服务、艺术和娱乐等领域寻求南澳政府、非政府和社区服务的帮助。',
            logo: blog16,
        },
    
]

const blogPosts = localStorage.getItem("language") != "cn" ? blogPost : blogPostCn
const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Resources', },
]

const BlogPost = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <Fragment>
            <div>
                <h5>
                    {localStorage.getItem("language") != "cn" ? "We hope the following third-party links may provide useful guidance:":"我们希望以下第三方链接可以提供有用的指导："}
                </h5>
            </div>


            {blogPosts.map((blog, i) => (
                <div key={i} className="blogPostWrapper">
                    <div className="blogPostImg">
                        <img src={blog.image} alt="" />
                    </div>
                    <div className="blogPostContent">
                        {/* <ul className="blogPostMeta">
                            {blog.meta.map((meta, i) => (
                                <Fragment key={i}>
                                    <li><img src={meta.avatar} alt=""/></li>
                                    <li>{meta.name}</li>
                                    <li>{meta.level}</li>
                                    <li>{meta.date}</li>
                                </Fragment>
                            ))}
                        </ul> */}
                        <h3>{blog.title}</h3>
                        <p>{blog.text}</p>
                        <div className='blogContentBottom'>
                            <a target="_blank" className="readmore" href={blog.url}>{localStorage.getItem("language") != "cn" ? "Visit": "访问"}</a>
                            <img src={blog.logo} alt={blog.title} />
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>
    )
}
export default BlogPost