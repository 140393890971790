import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import SearchSidebar from '../../../components/SearchSidebar'
import CetagorySidebar from '../../../components/CetagorySidebar'
import RecentPosts from '../../../components/RecentPosts'
import BlogPost from '../../../components/BlogPost'
import Tags from '../../../components/Tags'
import Instagram from '../../../components/Instagram'
// images
import breadcumb from '../../../images/slider/slider1.jpg'
import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Terms and Conditions' }

]

const BlogFullWidth = () => {

    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={localStorage.getItem("language") != "cn" ? "Terms and Conditions" : "条款声明"}
                breadcumbMenu={localStorage.getItem("language") != "cn" ? breadcumbMenu : [
                    { name: '首页', route: '/' },
                    { name: '条款声明' }
                ]}
                background={breadcumb}
            />
            <div className="blogPostArea blogFullWidth">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <h3>TERMS AND CONDITIONS</h3>
                                <p>The material contained on this website is for general guidance only and is not, nor intended to be, personal professional legal advice. The information is intended to apply within Australia only. The information provided should not be used as a substitute for personal professional legal advice, as the accuracy or effectiveness of any professional advice depends upon the particular circumstances of each case.  </p>


                                <p>You should consult a Yapp Lawyers professional prior to making any decisions pursuant to any material contained on this site. We do not take responsibility for omissions or inaccuracies in the information provided on this site given the changing nature of law, regulations, and electronic communication. In no event will Yapp Lawyers be liable to you or anyone for any actions or decisions taken in reliance on the information on this site. </p>
                                <p>Certain links on this site may connect you to other websites maintained by third parties over whom we do not have control. Yapp Lawyers makes no representations or warranties as to the accuracy or any other aspect of information contained in third party websites. </p>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default BlogFullWidth