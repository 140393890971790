import React from "react";
import avatarImg from "../../images/practice/4.jpg";
import single from "../../images/practice/ba.png";
import con from "../../images/practice/icon1.png";
import convey from "../../images/practice/convey.png";
import family from "../../images/practice/family.png";
import debt from "../../images/practice/debt.png";
import BannerSidebar from "../../components/BannerSidebar";
import banner from "../../images/practice/s2.png";
import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const cllistItem = [
  "Australian Business Number (ABN) application",
  "Australian Company Number (ACN) application",
  "Business contracts",
  "Business registration or business deregistration",
  "Business structure options",
  "Commercial disputes",
  "Commercial lease",
  "Director’s guarantee",
  "Disclosure statements",
  "Due diligence investigation",
  "Employment contracts",
  "General tax obligations",
  "Intellectual property",
  "Liquor licensing",
  "Shareholder agreements",
];

const cllistItemCn = [
  "申请澳大利亚商业号码(ABN)",
  "注册澳大利亚有限公司 (ACN)",
  "商业合同",
  "商业登记或商业注销",
  "企业架构选择",
  "商业纠纷",
  "商业租约",
  "董事担保",
  "披露声明",
  "尽职调查",
  "雇佣合同",
  "税务责任",
  "知识产权",
  "酒牌申请",
  "股东协议",
];

const conveyancinglistItem = [
  "Contract and Form 1 preparation and review",
  "Commercial property transactions",
  "Conveyancing services for developers",
  "Residential property transactions",
  "Strata and Community Applications",
  "Subdivision of land",
];
const conveyancinglistItemCn = [
  "起草和审阅合同及表格一（Form 1）",
  "商业地产交易",
  "开发商过户服务",
  "住宅地产交易",
  "分契式所有权和社区所有权申请",
  "土地分割",
];
const drlistItem = [
  "Debt agreement review",
  "Letter of demand",
  "Lodgement and filling at the court",
  "Enforcement of judgment",
];

const drlistItemCn = [
  "借款合同审阅",
  "出具律师催款函",
  "申请法庭立案",
  "执行判决",
];
const fllistItem = [
  "Consent orders",
  "Family law advice",
  "Financial agreements(divorce/separation)",
  "Pre-nuptial agreements",
  "Parenting matters",
  "Personal protection orders",
];
const fllistItemCn = [
  "法院同意令",
  "家庭法法律建议",
  "离婚/分居财产协议",
  "婚前财产协议",
  "父母监护相关事宜",
  "人身安全保护令",
];
const ewlistItem = [
  "Advanced Care Directive",
  "Binding Death Benefit Nominations",
  "Family Trusts",
  "Letter of Administration/Probate",
  "Power of Attorney",
  "Testamentary Trusts",
  "Will",
];

const ewlistItemCn = [
  "提前护理指示",
  "有约束力的死亡受益人提名",
  "家庭信托基金",
  "遗嘱认证",
  "授权书",
  "遗嘱信托",
  "遗嘱",
];
const cllistItems =
  localStorage.getItem("language") != "cn" ? cllistItem : cllistItemCn;
const conveyancinglistItems =
  localStorage.getItem("language") != "cn"
    ? conveyancinglistItem
    : conveyancinglistItemCn;
const drlistItems =
  localStorage.getItem("language") != "cn" ? drlistItem : drlistItemCn;
const fllistItems =
  localStorage.getItem("language") != "cn" ? fllistItem : fllistItemCn;
const ewlistItems =
  localStorage.getItem("language") != "cn" ? ewlistItem : ewlistItemCn;

const SingleContentArea = ({ className, avatar, image }) => {
  return (
    <div className={className}>
      {/* <div className="singleContentImg">
                <img src={single} alt="" />
            </div> */}
      <div className="row" id="ba">
        <div className="col-lg-7 singleContentWrap">
          <h3>
            {localStorage.getItem("language") != "cn"
              ? "Business Advisory"
              : "商务咨询"}
          </h3>
          <p>
            {localStorage.getItem("language") != "cn"
              ? "Yapp Lawyers works consistently and closely with business clients who operate their companies in South Australia and other States. Our experts have successfully proposed tailored business plans to comply with mandatory requirements from the relevant Australian authorities, including the Department of Home Affairs. Our specialists monitor the operation of your businesses from the establishment to ongoing concerns. We assist clients to mitigate risks, abide by corporate governance and achieve their objectives."
              : "叶氏律师事务所长期以来和我们的商业客户密切合作，其在南澳州或其他州经营自己的公司。我们专业的律师团队成功地为商业客户提供量身打造的商业计划，以符合澳洲相关政府机构的各种强制性要求，其中包括联邦移民局。我们的专家自您的企业成立之日起，全程监测您的企业运营。我们协助客户降低风险、管理公司并达成他们在各个方面的目标。"}{" "}
          </p>
        </div>
        <div className="col-lg-5">
          <aside className=" pr25">
            <BannerSidebar image={banner} className="bannerWrapper" />
          </aside>
        </div>
      </div>

      {/* <div className="singleContentImg">
                <img src={single} alt="" />
            </div> */}

      <div className="singleContentWrap" style={{ paddingTop: 50 }} id="cl">
        <h3>
          {localStorage.getItem("language") != "cn"
            ? "Commercial Law"
            : "商业法"}
        </h3>
        <p>
          {localStorage.getItem("language") != "cn"
            ? "Yapp Lawyers has a long history and good reputation for practising in the field of commerce. Our commercial team aims to combine legal knowledge with commercial insights to facilitate your businesses and further your interests. Our experienced commercial lawyers provide considered and thorough legal advice to clients on a wide range of business-related matters from business framework to company compliance. Legal services provided by our law firm include but not limited to:"
            : "叶氏律师事务所长期在商业领域提供法律服务，并建立了良好的声誉。我们的商业团队致力于融合商业洞察力和法律知识以促成您的业务以及促进您的企业收益增长。针对从企业架构到公司合规性等广泛的商业相关问题，我们的资深商业律师为客户提供深思熟虑的法律建议。我们律所提供的法律服务包括但不限于以下所列："}{" "}
        </p>
        <div className="row">
          <div className="col-lg-7 avatarWra" style={{ paddingLeft: 40 }}>
            <div className="row">
              {/* <h4>Family Law Organizations</h4> */}
              <ul>
                {cllistItems.map((list) => (
                  <li key={list}>{list}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-5 singleContentImg">
            <img src={single} alt="" style={{ paddingTop: 50 }} />
          </div>
        </div>
      </div>

      <div className="singleContentWrap" style={{ paddingTop: 50 }} id="con">
        <h3>
          {localStorage.getItem("language") != "cn"
            ? "Conveyancing"
            : "房产过户"}
        </h3>
        <p>
          {localStorage.getItem("language") != "cn"
            ? "We have earned a good reputation for efficiency, patience, attention to detail, and value for money. Our law firm provides high-quality conveyancing services to clients, including vendors and purchasers from various individual property transactions to commercial development projects. Our conveyancing team has abundant experience in assisting clients to settle properties in South Australia.  Our experienced registered conveyancers provide services to clients as below:"
            : "我们因提供高效、耐心、仔细和物超所值的服务而赢得了良好的声誉。我们律所为客户提供高质量的过户服务，我们的客户包括住宅买卖交易中的买方和卖方，以及商业地产开发项目中的买方和卖方。我们的过户团队在南澳州房屋交割方面有丰富的经验。我们的资深注册过户师们为客户提供以下服务："}{" "}
        </p>
        <div className="row">
          <div className="col-lg-7 avatarWra" style={{ paddingLeft: 40 }}>
            <div className="row">
              {/* <h4>Family Law Organizations</h4> */}
              <ul>
                {conveyancinglistItems.map((list) => (
                  <li key={list}>{list}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-5 singleContentImg">
            <img src={convey} alt="Conveyancing" />
          </div>
        </div>
      </div>

      <div className="singleContentWrap" style={{ paddingTop: 50 }} id="dr">
        <h3>
          {localStorage.getItem("language") != "cn"
            ? "Debt Recovery"
            : "债务追偿"}
        </h3>
        <p>
          {localStorage.getItem("language") != "cn"
            ? "It is prominent for companies, small business owners and individuals to secure a healthy cash flow. We understand that you may feel frustrated when your money cannot be chased back from borrowers. Our debt recovery professionals have a dedication to helping clients by providing feasible and tailored strategies. Our specialists are proficient in dealing with disputes and monitoring processes. We prefer solving legal issues and recovering your money out of court to save time and costs for you. Our legal services of debt recovery include:"
            : "无论公司、小企业业主还是个人，确保健康的现金流是非常重要的。我们理解您无法追回借款时的沮丧。我们的律师致力于帮助客户并为其提供可行的、量身打造的法律策略。我们的专家擅长处理纠纷以及监控整个过程。我们更倾向于不通过法庭程序帮您解决法律纠纷并追偿债务，以减少您的费用支出并节约您的时间。我们的债务追偿法律服务包括："}{" "}
        </p>
        <div className="row">
          <div className="col-lg-7 avatarWra" style={{ paddingLeft: 40 }}>
            <div className="row">
              {/* <h4>Family Law Organizations</h4> */}
              <ul>
                {drlistItems.map((list) => (
                  <li key={list}>{list}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-5 singleContentImg">
            <img src={debt} alt="Debt Recovery" />
          </div>
        </div>
      </div>

      <div className="singleContentWrap" style={{ paddingTop: 50 }} id="fl">
        <h3>
          {localStorage.getItem("language") != "cn" ? "Family Law" : "家庭法"}
        </h3>
        <p>
          {localStorage.getItem("language") != "cn"
            ? "Our lawyers understand your grievances during times of separation of divorce. Our family law team aims to offer straightforward legal advice to ease stress. We encourage clients to solve family law matters by arranging negotiations, instead of going through time-consuming and expensive litigation processes. Our dedicated team delivers the following services:"
            : "我们的律师理解您在离婚分居期间的不满。我们的家庭法团队致力于提供直接有效的法律建议以减轻您的压力。我们鼓励客户通过协商的方式解决家庭问题，而不是通过耗时长，费用高的诉讼程序。我们的团队致力于提供以下服务："}{" "}
        </p>
        <div className="row">
          <div className="col-lg-7 avatarWra" style={{ paddingLeft: 40 }}>
            <div className="row">
              {/* <h4>Family Law Organizations</h4> */}
              <ul>
                {fllistItems.map((list) => (
                  <li key={list}>{list}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-5 singleContentImg">
            <img src={family} alt="Family Law" style={{ width: 500 }} />
          </div>
        </div>
      </div>

      <div className="singleContentWrap" style={{ paddingTop: 50 }} id="we">
        <h3>
          {localStorage.getItem("language") != "cn"
            ? "Wills and Estate"
            : "遗嘱遗产"}
        </h3>
        <p>
          {localStorage.getItem("language") != "cn"
            ? "Estate planning is essential to everyone, in particular, when you mourn the loss of your beloved family members. A well-prepared testament means your beneficiaries will avoid excruciating estate disputes. It is never too early to formulate a succession plan to protect your assets and the benefits of your loved ones. Our estate planning experts assist clients to achieve the best outcome. We provide customised professional legal advice in the following services:"
            : "遗产规划对每个人都非常重要，尤其是在失去的家庭成员时。一份精心准备的遗产规划意味着您意属的遗产继承人将可以避免令人痛苦的遗产纠纷。提早制定继承人计划以保护您的资产和您意属之人。我们的遗产规划专家将协助客户达成最佳结果。我们提供以下个性化的专业法律建议："}{" "}
        </p>
        <div className="row">
          <div className="col-lg-7 avatarWra" style={{ paddingLeft: 40 }}>
            <div className="row">
              {/* <h4>Family Law Organizations</h4> */}
              <ul>
                {ewlistItems.map((list) => (
                  <li key={list}>{list}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-5 singleContentImg">
            <img src={con} alt="Wills and Estate" style={{ width: 500 }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleContentArea;
