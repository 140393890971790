import React, { useState, Fragment, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import logo from '../../images/logo/yapp.png'
import './style.scss'

const HeaderBottom = props => {
    const [search, setSearch] = useState()
    const [responsive, setResponsive] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const submitHandler = e => {
        e.preventDefault()
        console.log(search)
    }
    const clickHandler = () => {
        setTrigger(!trigger)
    }
    const responsiveHandler = () => {
        setResponsive(!responsive)
    }

    const setCurLanguage = () => {
        var currentLanguage = localStorage.getItem("language");
        console.log(currentLanguage);
        if (currentLanguage == null || currentLanguage == "en") {
            localStorage.setItem("language", "cn");
        } else if (currentLanguage == "cn") {
            localStorage.setItem("language", "en");
        }
        window.location.reload();
    }
    const [language, setLanguage] = useState("en");
    useEffect(() => {
        var currentLanguage = localStorage.getItem("language");
        if (currentLanguage == "cn") {
            setLanguage("cn");
        }
    });
    return (
        <div className={props.className}>
            <div className="container">
                <div className="headerBottomMainWrapper">
                    <div className="row">
                        <div className="col-lg-3 col-md-10 col-sm-6 col-8">
                            <div className="logo">
                                <NavLink to="/">
                                    <img src={logo} alt="YAPP LAWYERS" style={{ height: 70 }} />
                                </NavLink>
                            </div>
                        </div>
                        <div className={responsive ? "col-lg-8 responsiveWrapper active" : "col-lg-8 responsiveWrapper"}>
                            <ul className="mainMenuWrap">
                                <li><NavLink exact to='/'>{language == "en" ? "Home" : "首页"}</NavLink>
                                </li>
                                <li><NavLink exact to='/about'>{language == "en" ? "About Us" : "叶氏律师事务所"}</NavLink>
                                    {/* <ul className="subMenu">
                                        <li><NavLink exact to='/about#introduction'>Introduction</NavLink></li>
                                        <li><NavLink exact to='/about#vision'>Our vision</NavLink></li>
                                        <li><NavLink exact to='/about#team'>Our Team</NavLink></li>
                                    </ul> */}

                                </li>
                                <li><NavLink exact to='/expertise'>{language == "en" ? "Expertise" : "业务领域"}</NavLink>
                                    {/* <ul className="subMenu">
                                        <li><NavLink exact to='/ba'>Business Advisory</NavLink></li>
                                        <li><NavLink exact to='/cl'>Commercial Law</NavLink></li>
                                        <li><NavLink exact to='/cy'>Conveyancing</NavLink></li>
                                        <li><NavLink exact to='/dr'>Debt Recovery</NavLink></li>
                                        <li><NavLink exact to='/fl'>Family Law</NavLink></li>
                                        <li><NavLink exact to='/we'>Wills & Estate</NavLink></li>
                                    </ul> */}
                                </li>
                                {/*
                                <li><NavLink exact to='/case-stadies'>Cases</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/case-stadies'>Cases</NavLink></li>
                                        <li><NavLink exact to='/case-stadies-details'>Case single</NavLink></li>
                                    </ul>
                                </li>
                                */}
                                <li><NavLink exact to='/resources'>{language == "en" ? "Resources" : "新闻资讯"}</NavLink>

                                </li>
                                <li><NavLink exact to='/career'>{language == "en" ? "Join Us" : "加入我们"}</NavLink></li>
                                {/*
                                <li><NavLink exact to='/attorneys'>Attorneys</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/attorneys'>Attorneys</NavLink></li>
                                        <li><NavLink exact to='/attorneys-single'>Attorneys single</NavLink></li>
                                    </ul>
                                </li>
                                */}
                                <li><NavLink exact to='/contact'>{language == "en" ? "Contact Us" : "联系我们"}</NavLink></li>
                                <li><a onClick={setCurLanguage}>中/en</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-1 col-md-2 col-sm-6 col-4">
                            <div className="searchMenuWrapper">
                                {/* <div className="searchWrap">
                                    <i onClick={clickHandler} className="fa fa-search"></i>
                                    <div className={trigger ? 'searchform active' : 'searchform'}>
                                        <form onSubmit={submitHandler}>
                                            <input
                                                placeholder="search here"
                                                value={search}
                                                type="text"
                                                onChange={event => setSearch(event.target.value)}
                                            />
                                            <button><i className="fa fa-search"></i></button>
                                        </form>
                                    </div>
                                </div> */}
                                <div onClick={responsiveHandler} className="responsiveTrigger">
                                    <span className="first"></span>
                                    <span className="second"></span>
                                    <span className="third"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeaderBottom