import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import SearchSidebar from '../../../components/SearchSidebar'
import CetagorySidebar from '../../../components/CetagorySidebar'
import RecentPosts from '../../../components/RecentPosts'
import BlogPost from '../../../components/BlogPost'
import Tags from '../../../components/Tags'
import Instagram from '../../../components/Instagram'
// images
import breadcumb from '../../../images/slider/slider1.jpg'
import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Pricacy Policy' }

]

const BlogFullWidth = () => {

    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title={localStorage.getItem("language") != "cn" ? "Pricacy Policy" : "隐私政策"}
                breadcumbMenu={localStorage.getItem("language") != "cn" ? breadcumbMenu : [
                    { name: '首页', route: '/' },
                    { name: '隐私政策' }
                ]}
                background={breadcumb}
            />
            <div className="blogPostArea blogFullWidth">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <h3>PRIVACY POLICY</h3>
                                <p>Yapp Lawyers has a commitment to the privacy and confidentiality of information collected from clients pursuant to the Privacy Act 1988 (Cth). We only collect personal information as required or authorised by law. The Privacy Act provides you with information about how you can access your personal information, and to make you aware of how we collect, use and manage personal information. Yapp Lawyers is bound to its fiduciary duties, duty of confidentiality and legal professional privilege in all actions taken on our clients’ behalf. </p>
                                <br />
                                <h3>COLLECTION</h3>
                                <p>Under most circumstances we collect information directly from our clients. This may be through email, correspondence, in person or by telephone. Information we collect may include your name, contact details, copy drivers license and/or passport and any additional facts you provide that is in relation to the matter for which you seek advice or representation on. Depending on the nature of the matter, we may also request information from third parties such as, but not limited to, employers (past or present), your medical practitioner, or your bank.   </p>


                                <br />
                                <h3>PURPOSE OF COLLECTION</h3>
                                <p>We may collect personal information for the purposes of:</p>
                                <p>-	Providing legal services to our clients </p>
                                <p>-	Managing and accounting for our services </p>
                                <p>-	Communicating with our clients and contacts </p>
                                <p>-	Managing our employees and contractors </p>
                                <p>-	Planning and developing our services for the benefit of our clients </p>
                                <p>In the provision of legal services, advice and representation, we may supply information to agents to make enquiries or take action in relation to your matter. For example, at your instruction, a barrister may be engaged, or expert advice sought. </p>




                                <br />
                                <h3>USE AND DISCLOSURE OF INFORMATION</h3>
                                <p>We use and disclose client information for the primary purposes for which it has been provided. In addition to the information outlined under ‘Collection’ and ‘Purpose of Collection’, Yapp Lawyers also use and disclose information for secondary purposes such as invoicing, accurate file management and related correspondence. The services of agents, service providers or contractors may be engaged for secondary purposes. All such third parties must comply with the confidentiality and fiduciary duties set out in the Privacy Act in the course of handling and managing the information. </p>
                                <p>At your instruction or according to requirements of the law, we may disclose personal information to:</p>
                                <p>-	Our clients and their employees, contractors and other advisers </p>
                                <p>-	Our clients’ contacts and their employees and/or contractors </p>
                                <p>-	To our employees, contractors and advisers </p>
                                <p>-	Courts, tribunals and regulators</p>
                                <p>-	Government and reporting agencies</p>
                                <p>-	Debt collection agencies </p>
                                <p>-	To other individuals in connection with the provision of our professional services including witnesses and barristers </p>
                                <p>-	Any other persons as authorised by the individual</p>
                                <p>-	As otherwise authorised by the Privacy Act</p>

                                <br />
                                <h3>SECURITY OF INFORMATION</h3>
                                <p>The information provided to us by our clients is stored on computer or physical paper files as appropriate. All reasonable steps are taken to ensure the protection of information from misuse or loss, unauthorised access, disclosure or modification by means of password and network protection; as well as secure storage of physical files and other records.   </p>
                                <p>Additionally, all staff with access to clients’ personal information are bound by duties of confidentiality, and endeavour to keep such personal information private and to protect such information from misuse, loss, modification or disclosure due to unauthorised access. </p>

                                <br />
                                <h3>INTEGRITY OF PERSONAL INFORMATION</h3>
                                <p>We strive to ensure that the personal information collected from our client is up to date, complete and accurate. We will assume that any personal information provided is free from omissions and errors and is not misleading or deceptive.  </p>
                                <p>We encourage you to contact us if your circumstances change or you have reason to believe our records are not up to date or accurate. We will take all reasonable steps to ensure that the information will be amended in accordance with the Privacy Act. </p>




                                <br />
                                <h3>ACCESSING YOUR PERSONAL INFORMATION</h3>
                                <p>In the event a client requests access to personal information we hold, we may require the client to specify the information required and verify their identity. We deal with access to personal information as set out in the Privacy Act. We may be bound to duties and obligations under the law which may restrict your access. If access is provided, we may request a reasonable fee for time and other costs incurred in providing access, such as retrieval of files from archival storage.  </p>


                                <br />
                                <h3>COOKIES</h3>
                                <p>We may ask third parties to analyse traffic on our website and they may use cookies to do so. Cookies are small files of data stored on your computer by the websites you visit, and may be sent and accessed by the website’s server or your browser. Cookies are used to identify the user and collect information about the user’s identity, browser, preferences and website viewing patterns.  </p>

                                <br />
                                <h3>CHANGES TO PRIVACY POLICY</h3>
                                <p>We reserve the right to make changes without notice to this Privacy Policy from time to time. We recommend you check this page regularly to ensure that you are aware of the current Privacy Policy. We do not undertake to keep our website up to date and we are not liable if any information provided is inaccurate or outdated.  </p>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default BlogFullWidth