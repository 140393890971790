import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

const BannerSidebar = ({ className, image }) => {
    return (
        <div style={{ backgroundImage: `url(${image})` }} className={className}>
            <h3><span>40</span>{localStorage.getItem("language")!="cn"? " Years of Experience In This Field": " 年该领域的从业经验"}</h3>
            <div className="btnStyle">
                <Link to="/contact">{localStorage.getItem("language")!="cn"? "Contact Us Now" : "立刻联系我们"}</Link>
            </div>
        </div>
    )
}
export default BannerSidebar